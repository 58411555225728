import styled from 'styled-components';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Table } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { useDebouncedCallback } from 'use-debounce';
import { useLocale } from 'hooks';
import { initPagination, setLayoutAction, setPaginationAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { FormInput, Button } from 'components/_common';
import Filters from './Filters';
import { TableHeaders, PaginationType } from 'constants/index';
import { EmptyTable, TablePagination, Spinner, OverlayTriggerTooltip } from '@utiligize/shared/components';
import { IconExcel, IconSliders2 } from '@utiligize/shared/resources';

export interface Props {
  paginationType: Type.PaginationType;
  children: React.ReactNode[];
  totalAmount: number;
  hideFilters?: boolean;
  isEntriesHidden?: boolean;
  isSearchHidden?: boolean;
  customHeaders?: Type.DataTableHeader[];
  sendRequest: (params?: Type.SendRequestParams) => Promise<any>;
  containerClassName?: string;
  isDownloadCSVEnabled?: boolean;
  isResetFiltersButtonEnabled?: boolean;
  triggerTableUpdateDeps?: React.DependencyList;
  hover?: boolean;
  maxHeight?: string;
  waitForDependencies?: boolean;
  className?: string;
  isClientSidePagination?: boolean;

  // dashboard only
  isSettingsButtonEnabled?: boolean;
}

const DataTable: React.FC<Props> = ({
  paginationType,
  children,
  totalAmount,
  hideFilters = false,
  isEntriesHidden = false,
  isSearchHidden = false,
  customHeaders = null,
  sendRequest,
  containerClassName = '',
  isDownloadCSVEnabled = false,
  isResetFiltersButtonEnabled = false,
  isSettingsButtonEnabled = false,
  triggerTableUpdateDeps = [],
  hover = false,
  maxHeight,
  waitForDependencies,
  isClientSidePagination,
}) => {
  const {
    HISTORY,
    ANSWERS,
    UPCOMING_TASKS,
    TASKS,
    TASKS_FINISHED,
    TOTAL_HOURS,
    USERS_REGISTERED_HOURS,
    TASKS_REGISTERED_HOURS,
    NETWORK_LOADING_CUSTOMERS_TYPES,
    NEW_LOAD_DERS,
    NEW_LOAD_DERS_CATEGORIES,
    NEW_LOAD_DERS_PROFILES,
    NK_TASKS_MATERIALS,
    SETUP_RAW_DATA,
    PERMISSIONS_FEATURES,
    MANUALS,
    INVESTMENT_LIMITATIONS,
    SETUP_SCENARIO_CALCULATION,
    NEW_LOAD_CONNECTION_MANAGER,
    SETUP_CO2E,
    INVESTMENT_SCENARIOS,
    NEW_LOAD_DERS_PHASE_IN,
  } = PaginationType;

  const showFilters =
    !hideFilters &&
    [HISTORY, ANSWERS, UPCOMING_TASKS, TASKS, TASKS_FINISHED, TOTAL_HOURS, MANUALS].includes(paginationType);

  const hideEntries: boolean =
    isEntriesHidden ||
    [
      TOTAL_HOURS,
      USERS_REGISTERED_HOURS,
      TASKS_REGISTERED_HOURS,
      NETWORK_LOADING_CUSTOMERS_TYPES,
      NEW_LOAD_DERS,
      NEW_LOAD_DERS_CATEGORIES,
      NEW_LOAD_DERS_PROFILES,
      NK_TASKS_MATERIALS,
      SETUP_RAW_DATA,
      PERMISSIONS_FEATURES,
      INVESTMENT_LIMITATIONS,
      SETUP_SCENARIO_CALCULATION,
      NEW_LOAD_CONNECTION_MANAGER,
      SETUP_CO2E,
      INVESTMENT_SCENARIOS,
      NEW_LOAD_DERS_PHASE_IN,
    ].includes(paginationType);

  const hideSearch: boolean =
    isSearchHidden ||
    [
      NETWORK_LOADING_CUSTOMERS_TYPES,
      NEW_LOAD_DERS,
      NEW_LOAD_DERS_CATEGORIES,
      NEW_LOAD_DERS_PROFILES,
      NK_TASKS_MATERIALS,
      SETUP_RAW_DATA,
      PERMISSIONS_FEATURES,
      INVESTMENT_LIMITATIONS,
      SETUP_SCENARIO_CALCULATION,
      NEW_LOAD_CONNECTION_MANAGER,
      SETUP_CO2E,
      NEW_LOAD_DERS_PHASE_IN,
    ].includes(paginationType);

  const { limit, sort, column, offset, query, filters, columns }: Layouts.Pagination = useSelector(
    paginationSelectorFactory(paginationType)
  );

  const headers = (customHeaders || TableHeaders[paginationType]).reduce(
    (acc: Type.DataTableHeader[], item, index, array) => {
      if (!isSettingsButtonEnabled) return array;
      if (item.subTitles) {
        const subTitles = item.subTitles.filter(item => columns?.includes(item.key!) && !item.hidden);
        if (subTitles.length) acc.push({ ...item, subTitles });
      } else if ((columns?.includes(item.key!) && !item.hidden) || item.key === 'actions') {
        acc.push(item);
      }
      return acc;
    },
    []
  );

  const { getIntl, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();

  const [searchInput, setSearchInput] = useState<string>(query);
  const [loading, setLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const CSVTotalAmountLimit = 100000;
  const isCSVTotalAmountLimitExceeded = totalAmount > CSVTotalAmountLimit;
  const CSVFilesAmount = Math.ceil(totalAmount / CSVTotalAmountLimit);

  const debounced = useDebouncedCallback(
    () => dispatch(setPaginationAction({ type: paginationType, modifier: { query: searchInput, offset: 0 } })),
    1000
  );

  const handleSearchInputChange = useCallback(
    (event: React.SyntheticEvent) => {
      const value: string = (event.target as HTMLInputElement).value;
      setSearchInput(value);
      debounced();
    },
    [debounced]
  );

  const handleExportToCSVButtonClick = useCallback(async () => {
    if (!isDownloadCSVEnabled) return;
    setIsExporting(true);

    try {
      const array = Array.from({ length: CSVFilesAmount }, (_, i) => i);

      for (const skipPaginationOffset of array) {
        const items = await sendRequest({
          skipPagination: true,
          skipStoreUpdate: true,
          skipPaginationOffset: skipPaginationOffset * CSVTotalAmountLimit,
          skipPaginationLimit: CSVTotalAmountLimit,
        });

        if (Array.isArray(items)) {
          const worksheet = XLSX.utils.json_to_sheet(items);
          const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
          XLSX.writeFileXLSX(workbook, array.length > 1 ? `table-${skipPaginationOffset + 1}.xlsx` : 'table.xlsx');
        }
      }
    } finally {
      setIsExporting(false);
    }
  }, [isDownloadCSVEnabled, sendRequest, CSVFilesAmount]);

  useEffect(() => {
    // Navigate to previous page in case if last item deleted
    if (offset && totalAmount && totalAmount === offset) {
      dispatch(setPaginationAction({ type: paginationType, modifier: { offset: offset - limit } }));
    }
  }, [offset, limit, totalAmount, paginationType, dispatch]);

  const sendRequestDeps = [limit, sort, column, query, filters, ...triggerTableUpdateDeps, waitForDependencies].concat(
    isClientSidePagination ? [] : [offset]
  );
  useEffect(() => {
    if (!query) setSearchInput(''); // update input when cleared programmatically
    setLoading(true);
    if (waitForDependencies) return;
    sendRequest().finally(() => setLoading(false));
    return debounced.cancel;
  }, sendRequestDeps); // eslint-disable-line

  const setPage = useCallback(
    (currentPage: { selected: number }) => {
      dispatch(setPaginationAction({ type: paginationType, modifier: { offset: limit * currentPage.selected } }));
    },
    [paginationType, limit, dispatch]
  );

  const setSort = useCallback(
    (event: React.SyntheticEvent): void => {
      const column = event.currentTarget.getAttribute('data-name') as string;
      const sort = event.currentTarget.getAttribute('data-direction') as string;
      dispatch(setPaginationAction({ type: paginationType, modifier: { sort, column } }));
    },
    [paginationType, dispatch]
  );

  const setLimit = useCallback(
    (event: React.SyntheticEvent): void => {
      const limit: number = Number((event.target as HTMLSelectElement).value);
      dispatch(setPaginationAction({ type: paginationType, modifier: { limit, offset: 0 } }));
    },
    [paginationType, dispatch]
  );

  const isTwoRowsHeader = useMemo(() => headers.some(i => i.subTitles?.length), [headers]);

  const renderTableHeader = () => {
    const getTh = (item: Type.DataTableHeader, index: number) => (
      <th
        rowSpan={isTwoRowsHeader && !item.subTitles?.length ? 2 : 1}
        colSpan={item.subTitles?.length || 1}
        key={`${item.title || item.titleKey}_${index}`}
        className={classNames(item.className || 'text-left', {
          sorting_enabled: item.sortKey,
        })}
      >
        {item.title || getIntl(item.titleKey!)}
        {item.descriptionKey && (
          <>
            <br />
            <small className="text-muted">{getIntl(item.descriptionKey)}</small>
          </>
        )}
        {item.sortKey && (
          <>
            <StyledSortButton
              data-name={item.sortKey}
              data-direction="ASC"
              onClick={setSort}
              className={classNames('top', {
                active: column === item.sortKey && sort === 'ASC',
              })}
            />
            <StyledSortButton
              data-name={item.sortKey}
              data-direction="DESC"
              onClick={setSort}
              className={classNames('down', {
                active: column === item.sortKey && sort === 'DESC',
              })}
            />
          </>
        )}
      </th>
    );

    return (
      <>
        <tr role="row">{headers.map(getTh)}</tr>
        {isTwoRowsHeader && (
          <tr role="row">
            {headers.map((item: Type.DataTableHeader) => {
              if (!item.subTitles?.length) return null;
              return item.subTitles.map(getTh);
            })}
          </tr>
        )}
      </>
    );
  };

  const length = useMemo(
    () =>
      headers.reduce((acc: number, item: Type.DataTableHeader) => {
        if (!item.subTitles?.length) return acc;
        return acc + item.subTitles.length;
      }, headers.length),
    [headers]
  );

  const renderTableBody = () => {
    if (loading) {
      return (
        <EmptyTable dataMarker="table_is_loading" colSpan={length}>
          <Spinner />
        </EmptyTable>
      );
    }
    if (children?.filter(Boolean).length) return children;
    return (
      <EmptyTable dataMarker="table_is_empty" colSpan={length}>
        {getIntl('Table is empty')}
      </EmptyTable>
    );
  };

  const handleResetFilters = useCallback(() => {
    dispatch(
      setPaginationAction({
        type: paginationType,
        modifier: { filters: { ...initPagination[paginationType].filters }, offset: 0 },
      })
    );
  }, [dispatch, paginationType]);

  const handleSettingsButtonClick = useCallback(() => {
    dispatch(setLayoutAction({ tableSettingsModal: { type: paginationType, customHeaders } }));
  }, [dispatch, paginationType, customHeaders]);

  const renderExportToCSVButton = () => {
    const ButtonElement = (
      <Button
        variant="primary-outline"
        icon={<IconExcel />}
        onClick={handleExportToCSVButtonClick}
        loading={isExporting || loading}
        aria-label="Export to CSV"
        disabled={!totalAmount}
      />
    );

    if (!isCSVTotalAmountLimitExceeded || loading) return ButtonElement;

    return (
      <OverlayTriggerTooltip
        trigger={['hover', 'focus']}
        overlayId={paginationType}
        overlayChildren={getIntl(
          'Selection exceeds the maximum limit of {{CSVTotalAmountLimit}} items. {{CSVFilesAmount}} files will be downloaded.',
          { CSVFilesAmount, CSVTotalAmountLimit: numberFormat(CSVTotalAmountLimit) }
        )}
      >
        {ButtonElement}
      </OverlayTriggerTooltip>
    );
  };

  return (
    <StyledContainer className={containerClassName}>
      <div className="dataTables_wrapper dt-bootstrap4">
        {(!hideEntries || showFilters || !hideSearch || isDownloadCSVEnabled) && (
          <Row>
            <Col sm={12} md={4}>
              {!hideEntries && (
                <div className="dataTables_length">
                  <label>
                    {getIntl('Show')}{' '}
                    <select
                      value={limit}
                      onChange={setLimit}
                      className="custom-select custom-select-sm form-control form-control-sm"
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>{' '}
                    {getIntl('entries')}
                  </label>
                </div>
              )}
            </Col>
            <Col sm={12} md={8} className="d-flex justify-content-end align-items-start">
              {isSettingsButtonEnabled && (
                <Button
                  variant="primary-outline"
                  icon={<IconSliders2 />}
                  onClick={handleSettingsButtonClick}
                  aria-label="Settings"
                  className="mr-2"
                />
              )}
              {isResetFiltersButtonEnabled && (
                <Button
                  variant="primary-outline"
                  labelKey="Reset filters"
                  onClick={handleResetFilters}
                  disabled={JSON.stringify(filters) === JSON.stringify(initPagination[paginationType].filters)}
                  aria-label="Reset filters"
                  className="mr-2"
                />
              )}
              {isDownloadCSVEnabled && renderExportToCSVButton()}
              {showFilters && <Filters paginationType={paginationType} />}
              {!hideSearch && (
                <label className="ml-2">
                  <FormInput
                    value={searchInput}
                    onChange={handleSearchInputChange}
                    type="search"
                    size="sm"
                    placeholderKey="Type to filter"
                    isNew
                  />
                </label>
              )}
            </Col>
          </Row>
        )}
        <StyledTableContainer $maxHeight={maxHeight}>
          <StyledTable
            bordered
            className="utiligize-table dataTable mt-2"
            hover={!loading && hover}
            $scrollable={Boolean(maxHeight)}
          >
            <thead>{renderTableHeader()}</thead>
            <tbody>{renderTableBody()}</tbody>
          </StyledTable>
        </StyledTableContainer>
        {!loading && !hideEntries && (
          <div className="my-2 d-flex justify-content-end">
            {Boolean(totalAmount) && (
              <StyledInfoDiv role="status" data-marker="datatable_paging_status">
                {getIntl('Showing')} {offset + 1} {getIntl('to')}{' '}
                {offset + limit > totalAmount ? totalAmount : offset + limit} of {totalAmount}
              </StyledInfoDiv>
            )}
            {totalAmount / limit > 1 && (
              <TablePagination
                forcePage={offset && offset / limit}
                pageCount={Math.ceil(totalAmount / limit)}
                onPageChange={setPage}
              />
            )}
          </div>
        )}
      </div>
    </StyledContainer>
  );
};

const StyledInfoDiv = styled.div`
  color: ${props => props.theme.colors.purple800};
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const StyledContainer = styled.div`
  padding: 1rem;
  background: ${props => props.theme.colors.grey25};
  border-top: 1px solid ${props => props.theme.colors.grey100};
`;

const StyledTableContainer = styled.div<{ $maxHeight?: string }>`
  display: block;
  width: 100%;
  overflow-x: auto;
  ${props => props.theme.mixins.scroll};

  ${props =>
    props.$maxHeight &&
    `
      max-height: ${props.$maxHeight};
      border-bottom: 1px solid #dee2e6;
  `}
`;

const StyledTable = styled(Table)<{ $scrollable: boolean }>`
  background: white;

  ${props =>
    props.$scrollable &&
    `
    border-top: none;
    border-bottom: none;
    
    thead {
      position: sticky;
      top: 0;
      background: #ffffff;
      z-index: 2;

      th {
        border-top: 1px solid #dee2e6;
      }
    }
  `};

  &.table-hover tbody tr:hover {
    background-color: ${props => props.theme.colors.grey25};
    cursor: pointer;
    color: initial;

    > * {
      box-shadow: unset !important;
    }
  }
`;

const StyledSortButton = styled.div`
  display: inline;
  content: '';
  position: absolute;
  border: 6px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s ease-in;
  right: 10px;

  &.down {
    border-top: 10px solid ${props => props.theme.colors.purple50};
    bottom: calc(50% - 18px);

    &:hover,
    &.active {
      border-top-color: #1e134b;
    }
  }

  &.top {
    border-bottom: 10px solid ${props => props.theme.colors.purple50};
    top: calc(50% - 18px);

    &:hover,
    &.active {
      border-bottom-color: #1e134b;
    }
  }
`;

export default DataTable;
