import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: NewLoad.MapConnection['connection_side'];
}

const SelectConnectionSide: React.FC<Props> = ({ value, ...props }) => {
  const { getIntl } = useLocale();

  const options = useMemo(
    () => [
      { value: 'hv', label: getIntl('HV node') },
      { value: 'lv', label: getIntl('LV node') },
    ],
    [getIntl]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey="Connection side"
      value={value ? options.find(o => o.value === value) || null : null}
      options={options}
      variant="small"
    />
  );
};

export default SelectConnectionSide;
