import React, { useCallback, useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useEventPreventedExec } from 'hooks';
import { Modal, Button, FormInput, SelectYear, HiddenFormSubmit, SelectMaintenancePlans } from 'components/_common';
import { AssetCategories } from 'constants/index';
import { IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

const ModalDetailedPlan: React.FC = () => {
  const [show, setShow] = useState(false);
  const toggleModal = useCallback((): void => setShow(!show), [show]);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    submitForm,
    setFieldTouched,
    setValues,
    setFieldValue,
    resetForm,
  } = useConfiguredFormik({ toggleModal });
  const handleFormSubmit = useEventPreventedExec(submitForm);

  useEffect(() => {
    if (!show) resetForm();
  }, [show, resetForm]);

  const handleSelectMaintenancePlansChange = useCallback(
    (v: any) => {
      const assetCategoryCode =
        v?.assetCategoryCode !== AssetCategories.TH ? v?.assetCategoryCode || '' : AssetCategories.ST;
      setValues({
        ...values,
        planId: v?.value || null,
        planName: v?.label || '',
        assetCategoryCode,
        name: assetCategoryCode,
      });
    },
    [values, setValues]
  );

  return (
    <>
      <Button icon={<IconPlus />} labelKey="Change asset in plan" variant="primary" onClick={toggleModal} />
      <Modal
        show={show}
        onHide={toggleModal}
        titleKey="Change asset in plan"
        cancelButtonProps={{
          disabled: isSubmitting,
          onClick: toggleModal,
        }}
        submitButtonProps={{
          labelKey: 'Create',
          loading: isSubmitting,
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <SelectMaintenancePlans
              value={values.planId}
              onChange={handleSelectMaintenancePlansChange}
              onBlur={() => setFieldTouched('planId')}
              errorKey={Boolean(touched.planId && errors.planId) ? (errors.planId as string) : ''}
              variant="small"
            />
          </Form.Group>
          <Form.Group>
            <FormInput
              labelKey="Asset code"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
              isNew
            />
          </Form.Group>
          <Form.Group>
            <SelectYear
              labelKey="Override year"
              value={values.overrideYear}
              onChange={(v: any) => setFieldValue('overrideYear', v.value)}
              onBlur={() => setFieldTouched('overrideYear')}
              errorKey={Boolean(touched.overrideYear && errors.overrideYear) ? (errors.overrideYear as string) : ''}
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default ModalDetailedPlan;
