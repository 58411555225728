import styled from 'styled-components';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchScenarioYearsPossibleOptionsAction } from 'modules/options';
import { scenarioYearsOptionsSelector } from 'modules/options/selectors';
import { FormReactSelectProps, Checkbox, FormReactSelect } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | number[] | null;
  showAllAvailableYearsCheckbox?: boolean;
  onlyFuture?: boolean;
  skipFetch?: boolean;
}

const SelectScenarioYearsPossible: React.FC<Props> = ({
  labelKey = 'Select years',
  value,
  showAllAvailableYearsCheckbox = false,
  onlyFuture = false,
  isDisabled = false,
  skipFetch = false,
  onChange,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch: Shared.CustomDispatch = useDispatch();
  const scenarioYearsOptions = useSelector(scenarioYearsOptionsSelector);

  useEffect(() => {
    if (scenarioYearsOptions || skipFetch) return;
    setIsLoading(true);
    dispatch(fetchScenarioYearsPossibleOptionsAction()).finally(() => setIsLoading(false));
  }, [scenarioYearsOptions, dispatch, skipFetch]);

  const handleCheckboxChange = useCallback(
    (event: React.SyntheticEvent) => {
      const { checked } = event.target as HTMLInputElement;
      onChange?.(checked ? scenarioYearsOptions?.map(i => ({ value: i })) : [], {} as any);
    },
    [onChange, scenarioYearsOptions]
  );

  const selectOptions = useMemo(
    () =>
      (scenarioYearsOptions || []).reduce((acc: Type.SelectOption<number>[], year: number) => {
        if (onlyFuture && year < new Date().getFullYear()) return acc;
        acc.push({ value: year, label: String(year) });
        return acc;
      }, []),
    [scenarioYearsOptions, onlyFuture]
  );

  const selectValues = useMemo(
    () =>
      selectOptions.filter(option => (Array.isArray(value) ? value?.includes(option.value) : value === option.value)),
    [value, selectOptions]
  );

  return (
    <>
      <FormReactSelect
        isLoading={isLoading}
        isDisabled={!selectOptions.length || isDisabled}
        labelKey={labelKey}
        value={selectValues}
        options={selectOptions}
        isSearchable={false}
        onChange={onChange}
        variant="small"
        {...rest}
      />
      {showAllAvailableYearsCheckbox && (
        <StyledCheckbox
          className="icheck-primary"
          checked={
            Boolean(selectOptions?.length) && Array.isArray(value) && value?.length === scenarioYearsOptions?.length
          }
          labelKey="All available years"
          onChange={handleCheckboxChange}
        />
      )}
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
`;

export default SelectScenarioYearsPossible;
