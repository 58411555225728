import React, { useMemo } from 'react';
import { useLocale } from 'hooks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { SelectMinMaxValues } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Layouts.Filters['min_max'] | null;
}

const SelectMinMax: React.FC<Props> = ({ value = null, ...props }) => {
  const { getIntl } = useLocale();

  const { options, selectValue } = useMemo(
    () =>
      Object.keys(SelectMinMaxValues).reduce(
        (acc: any, key: string) => {
          const item = { value: key, label: getIntl(key) };
          if (value === key) acc.selectValue = item;
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValue: null }
      ),
    [value, getIntl]
  );

  return (
    <FormReactSelect
      value={selectValue}
      options={options}
      isSearchable={false}
      labelKey=""
      mutedTextLabelKey="Min/Max"
      variant="small"
      blurInputOnSelect
      {...props}
    />
  );
};

export default SelectMinMax;
