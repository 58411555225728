import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { usePaginationHandlers } from 'hooks';
import { DataTableTemplate } from 'components/_common';
import SelectMissingGeometryAssetType from './SelectMissingGeometryAssetType';
import TableMissingGeometry from './TableMissingGeometry';
import { PaginationType } from 'constants/index';

const ViewMissingGeometry: React.FC = () => {
  const type = PaginationType.DATA_QUALITY_MISSING_GEOMETRY;
  const { filters, handleSelectChange } = usePaginationHandlers({ type });

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={2}>
          <SelectMissingGeometryAssetType
            name="asset_type"
            mutedTextLabelKey="Asset type"
            value={filters?.asset_type}
            onChange={handleSelectChange}
            variant="small"
          />
        </Col>
      </Row>
      <TableMissingGeometry />
    </DataTableTemplate>
  );
};

export default ViewMissingGeometry;
