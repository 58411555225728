import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { paginationSelectorFactory, portfolioIdSelector } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { isRouteActiveSelectorFactory } from 'modules/router/selectors';
import ModalDER from './ModalDER';
import ModalSectionCategory from './ModalSectionCategory';
import ModalUploadIndividualCustomers from './ModalUploadIndividualCustomers';
import ModalUploadProfile from './ModalUploadProfile';
import ModalUploadPhaseIn from './ModalUploadPhaseIn';
import TableDERsCategories from './TableDERsCategories';
import TableManageDERs from './TableManageDERs';
import TableConnectionManager from './TableConnectionManager';
import TableDERsProfiles from './TableDERsProfiles';
import TableDERsPhaseIn from './TableDERsPhaseIn';
import { DataTableTemplate, Tabs, Tab, SelectScenarioYearsPossible, ButtonDownload, Spinner } from 'components/_common';
import { PaginationType, Routes } from 'constants/index';
import { scenarioYearsOptionsSelector } from 'modules/options/selectors';

const NewLoadManageDERsTabs: React.FC = () => {
  const { getIntl } = useLocale();
  const maxTableHeight = 'calc(100vh - 177px)';
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.NEW_LOAD_DERS));
  const portfolioId = useSelector(portfolioIdSelector);
  const years = useSelector(scenarioYearsOptionsSelector);
  const isCategoriesTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoadCategories));
  const isManageDERsTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoad));
  const isConnectionManagerTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoadConnectionManager));
  const isProfilesTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoadProfiles));
  const isPhaseInTabActive = useSelector(isRouteActiveSelectorFactory(Routes.NewLoadPhaseIn));

  const handleChangeDERsPreviewYearSelect = useCallback(
    (option: Type.SelectOption<number>) => {
      dispatch(
        setPaginationAction({
          type: PaginationType.NEW_LOAD_DERS,
          modifier: { filters: { ...filters, year: option?.value } },
        })
      );
    },
    [dispatch, filters]
  );

  return (
    <DataTableTemplate>
      {isCategoriesTabActive && <ModalSectionCategory />}
      {isManageDERsTabActive && (
        <Row>
          <Col xs={3} xl={2}>
            <SelectScenarioYearsPossible
              labelKey=""
              isSearchable={false}
              value={filters?.year || null}
              onChange={handleChangeDERsPreviewYearSelect}
              variant="small"
              blurInputOnSelect
            />
          </Col>
          <Col xs="auto">
            <ModalDER />
          </Col>
        </Row>
      )}
      {isConnectionManagerTabActive && (
        <>
          <ModalUploadIndividualCustomers />
          <ButtonDownload
            labelKey="Download template"
            fileNameLocaleStr="Individual customers excel template"
            link={`scenarios/individual_customers_excel?portfolio_id=${portfolioId}`}
            marginLeft
          />
        </>
      )}
      {isProfilesTabActive && (
        <>
          <ModalUploadProfile />

          <ButtonDownload
            labelKey="Download template"
            fileNameLocaleStr="Profile template"
            link="scenarios/der_profile_excel"
            marginLeft
          />
        </>
      )}
      {isPhaseInTabActive && (
        <>
          <ModalUploadPhaseIn />

          <ButtonDownload
            labelKey="Download template"
            fileNameLocaleStr="Phase-in template"
            link="scenarios/der_phase_in_excel"
            marginLeft
          />
        </>
      )}

      <Tabs mountOnEnter unmountOnExit>
        <Tab id="tab_new_load_categories" eventKey={Routes.NewLoadCategories} title={getIntl('Categories')}>
          <TableDERsCategories maxTableHeight={maxTableHeight} />
        </Tab>
        <Tab id="tab_new_load_ders" eventKey={Routes.NewLoad} title={getIntl('DERs')}>
          {!years ? (
            <Spinner inCard isInFullHeightContainer />
          ) : (
            <TableManageDERs year={filters?.year as number} maxTableHeight={maxTableHeight} />
          )}
        </Tab>
        <Tab
          id="tab_new_load_connection_manager"
          eventKey={Routes.NewLoadConnectionManager}
          title={getIntl('Connection manager')}
        >
          <TableConnectionManager maxTableHeight={maxTableHeight} />
        </Tab>
        <Tab id="tab_new_load_profiles" eventKey={Routes.NewLoadProfiles} title={getIntl('Profiles')}>
          <TableDERsProfiles maxTableHeight={maxTableHeight} />
        </Tab>
        <Tab id="tab_new_load_phase_in" eventKey={Routes.NewLoadPhaseIn} title={getIntl('Phase-in')}>
          <TableDERsPhaseIn maxTableHeight={maxTableHeight} />
        </Tab>
      </Tabs>
    </DataTableTemplate>
  );
};

export default NewLoadManageDERsTabs;
