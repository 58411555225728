import classNames from 'classnames';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { fetchAllAssetModelsAction } from 'modules/assetModels';
import { FormReactSelect, Alert, Button } from 'components/_common';
import { IconPlus, IconDelete } from '@utiligize/shared/resources';

const initialState = { modelCategoryId: null, modelManufacturerId: null, modelId: null };

interface Props {
  isDisabled?: boolean;
  modelIds: number[];
  assetCategoryCode?: Type.AssetCategories | null;
  setValue: (value: number[]) => void;
}

const SelectModels: React.FC<Props> = ({ isDisabled = false, modelIds, assetCategoryCode, setValue }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [models, setModels] = useState<AssetModels.Item[]>([]);
  const [{ modelCategoryId, modelManufacturerId, modelId }, setState] = useState(initialState);

  // assetCategoryCode preselected effect
  useEffect(() => {
    if (!assetCategoryCode || !models) return;
    const model = models.find(model => model.code === assetCategoryCode);
    if (!model) return;
    handleFieldsChange({ value: model.assetcategoryId }, { name: 'modelCategoryId' });
  }, [assetCategoryCode, models]); // eslint-disable-line

  useEffect(() => {
    dispatch(fetchAllAssetModelsAction()).then((action: Shared.ReduxAction<AssetModels.Item[]>) => {
      setModels(Object.values(action.payload || {}));
      setIsLoading(false);
    });
  }, [dispatch]);

  // filter out all models which already added to the list
  const filteredModels = useMemo(() => {
    return models.reduce((acc: AssetModels.Item[], model) => {
      if (modelIds.includes(model.modelId)) return acc;
      return [...acc, model];
    }, []);
  }, [models, modelIds]);

  const { categoriesSelectOptions, categoriesSelectValue } = useMemo(() => {
    const memo: number[] = [];
    return filteredModels.reduce(
      (acc: any, model) => {
        if (memo.includes(model.assetcategoryId)) return acc;
        memo.push(model.assetcategoryId);
        const option = { value: model.assetcategoryId, label: model.assetcategoryName };
        acc.categoriesSelectOptions = [...acc.categoriesSelectOptions, option];
        if (modelCategoryId === option.value) acc.categoriesSelectValue = option;
        return acc;
      },
      { categoriesSelectOptions: [], categoriesSelectValue: null }
    );
  }, [filteredModels, modelCategoryId]);

  const { manufacturerSelectOptions, manufacturerSelectValue } = useMemo(() => {
    const memo: number[] = [];
    return filteredModels.reduce(
      (acc: any, model) => {
        if (model.assetcategoryId !== modelCategoryId) return acc;
        if (memo.includes(model.manufacturerId)) return acc;
        memo.push(model.manufacturerId);
        const option = { value: model.manufacturerId, label: model.manufacturerName };
        acc.manufacturerSelectOptions = [...acc.manufacturerSelectOptions, option];
        if (modelManufacturerId === option.value) acc.manufacturerSelectValue = option;
        return acc;
      },
      { manufacturerSelectOptions: [], manufacturerSelectValue: null }
    );
  }, [modelCategoryId, modelManufacturerId, filteredModels]);

  const { modelSelectOptions, modelSelectValue } = useMemo(() => {
    return filteredModels.reduce(
      (acc: any, model) => {
        if (model.assetcategoryId !== modelCategoryId) return acc;
        if (model.manufacturerId !== modelManufacturerId) return acc;
        const option = { value: model.modelId, label: model.modelName };
        acc.modelSelectOptions = [...acc.modelSelectOptions, option];
        if (modelId === option.value) acc.modelSelectValue = option;
        return acc;
      },
      { modelSelectOptions: [], modelSelectValue: null }
    );
  }, [filteredModels, modelCategoryId, modelManufacturerId, modelId]);

  const handleFieldsChange = useCallback(
    (value: any, actionMeta: any) => {
      const name = actionMeta?.name;
      setState(state => ({
        ...state,
        [name]: value.value,
        ...(['modelCategoryId'].includes(name) && { modelManufacturerId: null }),
        ...(['modelCategoryId', 'modelManufacturerId'].includes(name) && { modelId: null }),
      }));
    },
    [setState]
  );

  const handleAddButtonClick = useCallback(() => {
    const model = filteredModels.find(model => {
      const isCategoryEqual: boolean = model.assetcategoryId === modelCategoryId;
      const isManufacturerEqual: boolean = !modelManufacturerId || model.manufacturerId === modelManufacturerId;
      const isModelEqual: boolean = !modelId || model.modelId === modelId;
      return isCategoryEqual && isManufacturerEqual && isModelEqual;
    });
    if (!model) return;
    setValue([model.modelId, ...modelIds]);
    // we have to keep modelCategoryId in case if assetCategoryCode came as a prop
    setState({ ...initialState, modelCategoryId: assetCategoryCode ? modelCategoryId : initialState.modelCategoryId });
  }, [filteredModels, modelIds, assetCategoryCode, modelCategoryId, modelManufacturerId, modelId, setValue]);

  const handleDeleteButtonClick = useCallback(
    (event: React.SyntheticEvent) => {
      const id: number = Number(event.currentTarget.getAttribute('data-model-id'));
      const nextModelsIds: number[] = modelIds.filter(modelId => modelId !== id);
      setValue(nextModelsIds);
    },
    [modelIds, setValue]
  );

  return (
    <>
      <Alert className="mb-2" variant="light">
        <small>{getIntl('Whenever you need to assign specific asset model, please use table below')}.</small>
      </Alert>
      <Row className="mb-2 align-items-end justify-content-start">
        {assetCategoryCode === undefined && (
          <Col>
            <FormReactSelect
              labelKey="Asset category"
              name="modelCategoryId"
              value={categoriesSelectValue}
              options={categoriesSelectOptions}
              onChange={handleFieldsChange}
              variant="small"
              isLoading={isLoading}
              isDisabled={isDisabled || isLoading}
            />
          </Col>
        )}
        <Col>
          <FormReactSelect
            labelKey="Manufacturer"
            name="modelManufacturerId"
            value={manufacturerSelectValue}
            options={manufacturerSelectOptions}
            onChange={handleFieldsChange}
            variant="small"
            isLoading={isLoading}
            isDisabled={!modelCategoryId || isDisabled || isLoading}
          />
        </Col>
        <Col>
          <FormReactSelect
            labelKey="Model"
            name="modelId"
            value={modelSelectValue}
            options={modelSelectOptions}
            onChange={handleFieldsChange}
            variant="small"
            isLoading={isLoading}
            isDisabled={!modelManufacturerId || isDisabled || isLoading}
          />
        </Col>
        <Col xs="auto">
          <Button
            disabled={!modelCategoryId || !modelManufacturerId || !modelId || isDisabled || isLoading}
            onClick={handleAddButtonClick}
            icon={<IconPlus />}
          />
        </Col>
      </Row>

      {models
        .filter(model => modelIds.includes(model.modelId))
        .map((model: AssetModels.Item, index: number, arr) => (
          <Row key={model.modelId} className={classNames('align-items-center', { 'mb-2': arr.length - 1 > index })}>
            {assetCategoryCode === undefined && <Col>{model.assetcategoryName}</Col>}
            <Col>{model.manufacturerName}</Col>
            <Col>{model.modelName}</Col>
            <Col xs="auto">
              <Button
                icon={<IconDelete />}
                data-model-id={model.modelId}
                onClick={handleDeleteButtonClick}
                disabled={isDisabled || isLoading}
              />
            </Col>
          </Row>
        ))}
    </>
  );
};

export default SelectModels;
