import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getEvolutionChartData } from 'modules/networkLoading';
import { portfolioIdSelector, scenarioIdSelector, selectedChartFlexSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { Checkbox } from 'components/_common';
import Chart, { getBaseOptions } from '../Chart';
import SelectEvolutionType from './SelectEvolutionType';
import ChartControlsContainer from '../ChartControlsContainer';

interface ChartDataProps {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  series: Shared.SeriesOptionsType[];
  categories: string[];
}

interface Props {
  uuid: string;
  height?: '100%';
  isTooltipOutside?: boolean;
}

const EvolutionChart: React.FC<Props> = ({ uuid, height, isTooltipOutside = false }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartDataHash, setChartDataHash] = useState<Record<string, ChartDataProps> | null>(null);
  const [type, setType] = useState<string | null>(null);
  const flex = useSelector(selectedChartFlexSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const chartData = type ? chartDataHash?.[type] : null;

  const handleSelectEvolutionTypeChange = useCallback((option: Type.SelectOption<string>) => setType(option.value), []);

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(setLayoutAction({ selectedChartFlex: event.currentTarget.checked })),
    [dispatch]
  );

  useEffect(() => {
    setChartDataHash(null);
    if (!portfolioId || !scenarioId || !uuid) return;
    dispatch(getEvolutionChartData({ portfolioId, scenarioId, uuid, flex })).then(action => {
      setChartDataHash(action.payload);
      setType(Object.keys(action.payload)[0]);
    });
  }, [dispatch, portfolioId, scenarioId, uuid, flex]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, chartData!);
    return {
      ...baseOptions,
      tooltip: {
        outside: isTooltipOutside,
      },
      xAxis: {
        ...baseOptions.xAxis,
        categories: chartData?.categories?.map(c => getIntl(c)),
      },
      series: chartData?.series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
    };
  }, [chartData, getIntl, isTooltipOutside]) as unknown as Highcharts.Options;

  const evolutionTypeOptions = useMemo(() => {
    return Object.keys(chartDataHash || {}).map(key => ({ value: key, label: getIntl(key) }));
  }, [chartDataHash, getIntl]);

  return (
    <>
      <ChartControlsContainer dataMarker="asset_evolution_chart__buttons_block">
        <SelectEvolutionType
          labelKey=""
          value={type}
          options={evolutionTypeOptions}
          onChange={handleSelectEvolutionTypeChange}
          variant="small"
          className="mr-1"
          isDisabled={!evolutionTypeOptions.length}
        />
        <Checkbox
          labelKey="Flex"
          className="icheck-primary ml-1"
          name="flex"
          checked={flex}
          onChange={handleCheckboxClick}
        />
      </ChartControlsContainer>

      <Chart key={type} dataMarker="asset_evolution_chart" options={chartData ? options : null} height={height} />
    </>
  );
};

export default EvolutionChart;
