import moment from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { selectedTimezoneSelector } from 'modules/app/selectors';
import { setTimezoneAction } from 'modules/app';
import { FormReactSelect } from 'components/_common';
import { getMatchingTimezoneOption } from 'utils';
import { TimezoneOptions } from 'constants/index';

const SelectTimezone: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch = useDispatch();
  const selectedTimezone = useSelector(selectedTimezoneSelector);

  const getZoneOffset = useCallback((zone: string) => moment.tz(zone).utcOffset() / 60, []);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption<string>): void => {
      dispatch(setTimezoneAction(option.value));
    },
    [dispatch]
  );

  const guessZoneOption = useMemo(() => getMatchingTimezoneOption(moment.tz.guess()), []);

  const { selectOptions, selectOption } = useMemo(
    () =>
      TimezoneOptions.reduce(
        (acc: { selectOptions: Type.SelectOption<string>[]; selectOption: Type.SelectOption<string> | null }, t) => {
          const utcOffset = getZoneOffset(t.value);
          const item = {
            ...t,
            label: `${t.label} (${utcOffset !== 0 ? `UTC${utcOffset > 0 ? '+' : ''}${utcOffset}` : 'GTM'})`,
          };
          if (t.value === selectedTimezone) acc.selectOption = item;
          acc.selectOptions.push(item);
          return acc;
        },
        { selectOptions: [], selectOption: null }
      ),
    [selectedTimezone, getZoneOffset]
  );

  return (
    <>
      <FormReactSelect
        labelKey="Select timezone"
        value={selectOption}
        options={selectOptions}
        onChange={handleSelectChange}
        isSearchable
        blurInputOnSelect
        variant="small"
      />
      {getZoneOffset(guessZoneOption.value) !== getZoneOffset(selectedTimezone) && (
        <small className="text-muted">{getIntl('You are in {{zone}}', { zone: guessZoneOption.label })}</small>
      )}
    </>
  );
};

export default SelectTimezone;
