import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import TableAssetModels from './TableAssetModels';
import { AssetModelsModal } from 'components/_modals';
import { DataTableTemplate, SelectAssetCategories, SelectSubComponents } from 'components/_common';
import { PaginationType } from 'constants/index';

const ViewModels: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.ASSET_MODELS));

  const handleChangeCategorySelect = useCallback(
    (value: any) => {
      dispatch(
        setPaginationAction({
          type: PaginationType.ASSET_MODELS,
          modifier: {
            filters: { ...filters, assetcategoryCode: value.value, subComponentTypeId: null },
            offset: 0,
          },
        })
      );
    },
    [dispatch, filters]
  );

  const handleSubComponentChange = useCallback(
    (subComponentTypeId: number | null) => {
      dispatch(
        setPaginationAction({
          type: PaginationType.ASSET_MODELS,
          modifier: { filters: { ...filters, subComponentTypeId }, offset: 0 },
        })
      );
    },
    [dispatch, filters]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col sm={3}>
          <SelectAssetCategories
            labelKey=""
            value={filters?.assetcategoryCode || ''}
            onChange={handleChangeCategorySelect}
            excludeSyntheticOptions
            variant="small"
          />
        </Col>
        <Col sm={3}>
          <SelectSubComponents
            labelKey=""
            assetCategoryCode={filters?.assetcategoryCode || null}
            setFieldValue={handleSubComponentChange}
            value={filters?.subComponentTypeId || null}
            customOptions={[{ value: 'unassigned', label: getIntl('Unassigned') }]}
            isReadOnly
            variant="small"
          />
        </Col>
        <Col>
          <AssetModelsModal />
        </Col>
      </Row>
      <TableAssetModels />
    </DataTableTemplate>
  );
};

export default ViewModels;
