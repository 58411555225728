import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useLocale, useToggle, useUsers, useEventPreventedExec } from 'hooks';
import { Button, SelectUser, FormInput, Modal, HiddenFormSubmit, Checkbox } from 'components/_common';
import { IconEdit, IconPlus } from '@utiligize/shared/resources';
import useConfiguredFormik from './useConfiguredFormik';

interface Props {
  isEditMode?: boolean;
}

const InspectionsModal: React.FC<Props> = ({ isEditMode = false }) => {
  const { getIntl } = useLocale();
  const { usersIds } = useUsers();
  const [show, toggleModal] = useToggle();
  const formik = useConfiguredFormik({ isEditMode, toggleModal, usersIds });
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched, submitForm, resetForm } =
    formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  const handleCancelButtonClick = useCallback(() => {
    toggleModal();
    resetForm();
  }, [toggleModal, resetForm]);

  const handleUserMultiSelectChange = useCallback(
    (userIds: number[]) => {
      setFieldValue('userIds', userIds);
    },
    [setFieldValue]
  );

  const handleCheckboxChange = useCallback(
    (event: React.SyntheticEvent) => {
      const isAllUsersChecked = (event.target as HTMLInputElement).checked;
      if (isAllUsersChecked) setFieldValue('userIds', usersIds);
      setFieldValue('isAllUsersChecked', isAllUsersChecked);
    },
    [usersIds, setFieldValue]
  );

  return (
    <>
      <Button
        className={isEditMode ? 'ml-auto mr-2' : ''}
        variant={isEditMode ? undefined : 'primary'}
        icon={isEditMode ? <IconEdit /> : <IconPlus />}
        labelKey={isEditMode ? '' : 'Create Tool inspection'}
        onClick={toggleModal}
      />
      <Modal
        show={show}
        onHide={handleCancelButtonClick}
        titleKey={getIntl(isEditMode ? 'Edit Tool inspection' : 'Create Tool inspection')}
        cancelButtonProps={{
          onClick: handleCancelButtonClick,
        }}
        submitButtonProps={{
          labelKey: isEditMode ? 'Update' : 'Create',
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Form.Group>
            <FormInput
              autoFocus
              labelKey="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
              isNew
            />
          </Form.Group>
          <Form.Group>
            <SelectUser
              isDisabled={values.isAllUsersChecked}
              errorKey={Boolean(touched.userIds && errors.userIds) ? (errors.userIds as string) : ''}
              userIds={values.userIds}
              setUserIds={handleUserMultiSelectChange}
              isMulti
              onBlur={() => setFieldTouched('userIds')}
              variant="small"
            />
          </Form.Group>
          <Form.Group>
            <Checkbox
              className="pl-0 icheck-primary"
              name="isAllUsersChecked"
              checked={values.isAllUsersChecked}
              labelKey="Add all users"
              onChange={handleCheckboxChange}
            />
          </Form.Group>
          <HiddenFormSubmit />
        </Form>
      </Modal>
    </>
  );
};

export default InspectionsModal;
