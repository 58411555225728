import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { initPagination, setPaginationAction } from 'modules/layouts';
import {
  paginationSelectorFactory,
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
} from 'modules/layouts/selectors';
import { fetchScenarioYearsOptionsAction } from 'modules/options';
import { scenariosYearsOptionsSelector, simulationVersionIdSelector } from 'modules/options/selectors';
import { SelectGridZone, SelectYear, Checkbox, SelectPrimarySubstations, SelectVoltageLevel } from 'components/_common';
import SelectInvestmentAssetCategory from './SelectInvestmentAssetCategory';
import SelectInvestmentReason from './SelectInvestmentReason';
import SelectSummaryGroup from './SelectSummaryGroup';
import { InvestmentSelectYearRange } from './utils';
import { PaginationType } from 'constants/index';

const TableFilters: React.FC<{ type: Type.PaginationType }> = ({ type }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { column, filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));

  const scenariosYearsOptions = useSelector(scenariosYearsOptionsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);

  useEffect(() => {
    if (!portfolioId || !scenarioId || !simulationId || !versionId || scenariosYearsOptions) return;
    dispatch(fetchScenarioYearsOptionsAction({ portfolioId, scenarioId, simulationId, versionId }));
  }, [dispatch, portfolioId, scenarioId, versionId, scenariosYearsOptions, simulationId]);

  const handleSelectChange = useCallback(
    (option: Type.SelectOption | Type.SelectOption[], meta: Type.SelectActionMetaBase) => {
      const value = Array.isArray(option) ? option.map(i => i.value) : option?.value;
      const modifier = (() => {
        if (meta.name === 'startYear' && filters?.endYear && Number(value) > filters?.endYear) {
          return { [meta.name]: value, endYear: value };
        }
        if (meta.name === 'endYear' && filters?.startYear && Number(value) < filters?.startYear) {
          return { [meta.name]: value, startYear: value };
        }
        return { [meta.name]: value };
      })();

      dispatch(
        setPaginationAction({
          type,
          modifier: {
            column: type === PaginationType.SUMMARY_PLAN ? initPagination[type].column : column,
            filters: { ...filters, ...modifier },
            offset: 0,
          },
        })
      );
    },
    [dispatch, filters, type, column]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setPaginationAction({
          type,
          modifier: { filters: { ...filters, [event.currentTarget.name]: event.currentTarget.checked }, offset: 0 },
        })
      );
    },
    [type, dispatch, filters]
  );

  return (
    <Row style={{ rowGap: '0.5rem' }}>
      <Col xs={2}>
        <SelectYear
          name="startYear"
          value={filters?.startYear || null}
          onChange={handleSelectChange}
          labelKey=""
          range={InvestmentSelectYearRange}
          isSearchable={false}
          mutedTextLabelKey="Start year"
          variant="small"
          isClearable
        />
      </Col>
      <Col xs={2}>
        <SelectYear
          name="endYear"
          value={filters?.endYear || null}
          onChange={handleSelectChange}
          labelKey=""
          range={InvestmentSelectYearRange}
          isSearchable={false}
          mutedTextLabelKey="End year"
          variant="small"
          isClearable
        />
      </Col>
      <Col xs={3}>
        <SelectPrimarySubstations
          name="primarySubstationsIds"
          value={filters?.primarySubstationsIds ?? null}
          onChange={handleSelectChange}
          isMulti
        />
      </Col>
      {type === PaginationType.SUMMARY_PLAN && (
        <Col xs={4}>
          <SelectSummaryGroup value={filters?.summaryGroupIds || null} onChange={handleSelectChange} variant="small" />
        </Col>
      )}
      <Col xs={type === PaginationType.DETAILED_INVESTMENTS ? 5 : 1} className="d-flex">
        <Checkbox
          labelKey="Flex"
          className="icheck-primary ml-3"
          name="flex"
          checked={filters?.flex}
          onChange={handleCheckboxClick}
        />
      </Col>
      <Col xs={3}>
        <SelectGridZone
          name="gridZones"
          value={filters?.gridZones ?? null}
          onChange={handleSelectChange}
          labelKey=""
          mutedTextLabelKey="Grid zones"
          variant="small"
          isMulti
        />
      </Col>
      <Col xs={3}>
        <SelectVoltageLevel
          value={filters?.voltageLevels ?? null}
          onChange={handleSelectChange}
          mutedTextLabelKey="Voltage level"
          variant="small"
          isMulti
          isClearable={false}
        />
      </Col>
      <Col xs={3}>
        <SelectInvestmentAssetCategory value={filters?.assetCategories ?? null} onChange={handleSelectChange} />
      </Col>
      <Col xs={3}>
        <SelectInvestmentReason value={filters?.replacementReasons || null} onChange={handleSelectChange} />
      </Col>
      <Col xs="auto" className="d-flex ml-auto">
        <small className="text-muted">
          <span
            dangerouslySetInnerHTML={{
              __html: getIntl('Investments are based on 99.9<sup>th</sup> percentile results in {{year}}', {
                year: scenariosYearsOptions?.[scenariosYearsOptions?.length - 1]?.value || '-',
              }),
            }}
          />
        </small>
      </Col>
    </Row>
  );
};

export default TableFilters;
