import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useToggle, useEventPreventedExec, useLocale, useBuilderState } from 'hooks';
import { formsModalIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { formsItemSelectorFactory } from 'modules/forms/selectors';
import {
  Alert,
  Button,
  FormInput,
  Modal,
  SelectFormFormsTypes,
  SelectSubComponents,
  HiddenFormSubmit,
  SelectAssetCategories,
  SelectTimestampOptions,
  SelectAssetCodes,
  SelectModels,
} from 'components/_common';
import useConfiguredFormik from './useConfiguredFormik';
import { BuilderFormTypeNames } from 'constants/index';
import { IconEdit, IconPlus } from '@utiligize/shared/resources';

interface Props {
  isEditMode?: boolean;
}

const FormsModal: React.FC<Props> = ({ isEditMode = false }) => {
  const { getIntl, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const formsModalId: number | null = useSelector(formsModalIdSelector); // exist on in duplicate mode
  const form: Forms.Item | undefined = useSelector(formsItemSelectorFactory(formsModalId)); // exist on in duplicate mode
  const isDuplicateMode = Boolean(formsModalId && form);
  const [show, toggleModal] = useToggle();
  const { snapshots } = useBuilderState();
  const formik = useConfiguredFormik({ formsModalId, isEditMode, toggleModal });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    resetForm,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = formik;
  const handleFormSubmit = useEventPreventedExec(submitForm);

  const getIsAssetsSelectorsVisible = useCallback(
    (formType: Builder.FormTypeNames) => ![BuilderFormTypeNames.TaskRelatedForm].includes(formType),
    []
  );

  const isAssetsSelectorsVisible = getIsAssetsSelectorsVisible(values.formType);

  // set form initial values asynchronously for isDuplicateMode
  useEffect(() => {
    if (!form) return;
    setValues({
      id: form.id,
      name: form.name,
      formType: form.formtype.name,
      assetCategoryCode: form.assetcategory?.code || null,
      assetCodes: form.assetCodes,
      modelIds: form.assignedModels.map(i => i.modelId),
      formSubComponentId: form.formsubcomponent?.id || null,
      timestampOptions: form.timestampOptions || null,
      error: '',
    });
  }, [form, setValues]);

  const handleCancelButtonClick = useCallback(() => {
    if (show) toggleModal();
    if (formsModalId) dispatch(setLayoutAction({ formsModalId: null }));
    resetForm();
  }, [show, toggleModal, formsModalId, dispatch, resetForm]);

  const handleFormTypeChange = useCallback(
    (option: Type.SelectOption<number>) => {
      setValues({
        ...values,
        formType: option.label as Builder.FormTypeNames,
        ...(!getIsAssetsSelectorsVisible(option.label as Builder.FormTypeNames) && {
          assetCategoryCode: null,
          assetCodes: [],
          modelIds: [],
        }),
        formSubComponentId: null,
        timestampOptions: null,
      });
    },
    [values, getIsAssetsSelectorsVisible, setValues]
  );

  const handleAssetCategoryChange = useCallback(
    (value: any) => {
      setValues({
        ...values,
        assetCategoryCode: value?.value || null,
        assetCodes: [],
        modelIds: [],
        formSubComponentId: null,
        timestampOptions: null,
      });
    },
    [values, setValues]
  );

  const handleAssetCodesSelectChange = useCallback(
    (options: Type.SelectOption[] | null) => {
      setFieldValue(
        'assetCodes',
        options?.map(o => o.value)
      );
    },
    [setFieldValue]
  );

  const handleSubComponentSetFieldValue = useCallback(
    (id: number | null) => setFieldValue('formSubComponentId', id),
    [setFieldValue]
  );

  const handleTimestampSelectChange = useCallback(
    (timestampOptions: string[]) => setFieldValue('timestampOptions', timestampOptions),
    [setFieldValue]
  );

  const revisions = useMemo(() => {
    if (!snapshots) return null;
    return snapshots.splice(0, 1).map((item: Builder.Snapshot) => <p key={item.id}>{dateFormat(item.createdAt)}</p>);
  }, [snapshots, dateFormat]);

  return (
    <>
      {isEditMode ? (
        <Button
          tooltipKey="Edit Form"
          icon={<IconEdit />}
          className="ml-auto mr-2"
          onClick={toggleModal}
          variant="primary-outline"
        />
      ) : (
        <Button icon={<IconPlus />} labelKey="Create form" onClick={toggleModal} variant="primary" />
      )}
      <Modal
        show={show || isDuplicateMode}
        onHide={handleCancelButtonClick}
        titleKey={isDuplicateMode ? 'Duplicate Form' : isEditMode ? 'Edit Form' : 'Create form'}
        cancelButtonProps={{
          onClick: handleCancelButtonClick,
        }}
        submitButtonProps={{
          labelKey: isDuplicateMode ? 'Duplicate' : isEditMode ? 'Update' : 'Create',
          onClick: handleFormSubmit,
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <Row>
            {isEditMode && (
              <Form.Group as={Col} sm={2} className="border-right">
                <Form.Text>{getIntl('Revisions')}</Form.Text>
                {revisions}
              </Form.Group>
            )}
            <Col sm={isEditMode ? 10 : 12} className={isEditMode ? 'pl-4' : ''}>
              <Form.Row>
                <Form.Group as={Col}>
                  <FormInput
                    autoFocus
                    labelKey="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorKey={Boolean(touched.name && errors.name) ? (errors.name as string) : ''}
                    isNew
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <SelectFormFormsTypes
                    name="formType"
                    label={values.formType as string}
                    onChange={handleFormTypeChange}
                    onBlur={handleBlur}
                    errorKey={Boolean(touched.formType && errors.formType) ? (errors.formType as string) : ''}
                    isDisabled={isDuplicateMode || isEditMode}
                    variant="small"
                  />
                </Form.Group>
              </Form.Row>
              {isAssetsSelectorsVisible && (
                <>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <SelectAssetCategories
                        value={values.assetCategoryCode}
                        onChange={handleAssetCategoryChange}
                        onBlur={() => formik.setFieldTouched('assetCategoryCode')}
                        errorKey={
                          Boolean(touched.assetCategoryCode && errors.assetCategoryCode)
                            ? (errors.assetCategoryCode as string)
                            : ''
                        }
                        isDisabled={isDuplicateMode || isEditMode}
                        excludeSyntheticOptions
                        isClearable
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <SelectAssetCodes
                        values={values.assetCodes}
                        onChange={handleAssetCodesSelectChange}
                        onBlur={() => setFieldTouched('assetCodes')}
                        errorKey={Boolean(touched.assetCodes && errors.assetCodes) ? (errors.assetCodes as string) : ''}
                        isDisabled={
                          isDuplicateMode || isEditMode || !values.assetCategoryCode || Boolean(values.modelIds.length)
                        }
                        isMulti
                        assetCategoryCode={values.assetCategoryCode}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Group>
                    <SelectModels
                      modelIds={values.modelIds}
                      setValue={modelIds => setFieldValue('modelIds', modelIds)}
                      isDisabled={
                        isDuplicateMode || isEditMode || !values.assetCategoryCode || Boolean(values.assetCodes.length)
                      }
                      assetCategoryCode={values.assetCategoryCode}
                    />
                  </Form.Group>
                </>
              )}

              {values.formType === BuilderFormTypeNames.Repair && (
                <Form.Group>
                  <SelectSubComponents
                    assetCategoryCode={values.assetCategoryCode}
                    setFieldValue={handleSubComponentSetFieldValue}
                    value={values.formSubComponentId}
                    errorKey={
                      Boolean(touched.formSubComponentId && errors.formSubComponentId)
                        ? (errors.formSubComponentId as string)
                        : ''
                    }
                    disabled={!values.assetCategoryCode || isDuplicateMode || isEditMode}
                  />
                </Form.Group>
              )}

              {isAssetsSelectorsVisible && (
                <Form.Group>
                  <SelectTimestampOptions
                    isDisabled={!values.assetCategoryCode || isDuplicateMode || isEditMode}
                    errorKey={
                      Boolean(touched.timestampOptions && errors.timestampOptions)
                        ? (errors.timestampOptions as string)
                        : ''
                    }
                    timestampOptions={values.timestampOptions || []}
                    setValues={handleTimestampSelectChange}
                    isMulti
                    onBlur={() => setFieldTouched('timestampOptions')}
                    assetCategoryCode={values.assetCategoryCode}
                    variant="small"
                  />
                </Form.Group>
              )}
              <Alert
                show={!isDuplicateMode && !isEditMode}
                variant="light"
                className={classNames('mt-0', { 'mb-0': !Boolean(errors.error) })}
              >
                <small>
                  {getIntl('Remember')}
                  <br />
                  {getIntl('Remember2')}
                </small>
              </Alert>
              <Alert show={Boolean(errors.error)} variant="danger">
                {getIntl(errors.error as string)}
              </Alert>
              <HiddenFormSubmit />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default FormsModal;
