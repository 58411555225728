import React, { useMemo, useEffect } from 'react';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { useSelector, useDispatch } from 'react-redux';
import { manufacturersItemsSelector } from 'modules/manufacturers/selectors';
import { fetchManufacturersAction } from 'modules/manufacturers';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options' | 'labelKey'> {
  value: number | null;
}

const SelectFormManufacturers: React.FC<Props> = ({ value, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const items = useSelector(manufacturersItemsSelector);

  useEffect(() => {
    dispatch(fetchManufacturersAction({ skipFilters: true, skipPagination: true }));
  }, [dispatch]);

  const { selectValue, selectOptions } = useMemo(
    () =>
      items.reduce(
        (
          acc: { selectValue: Type.SelectOption | null; selectOptions: Type.SelectOption[] },
          item: Manufacturers.Item
        ) => {
          const option = { value: item.id, label: item.name };
          if (option.value === value) acc.selectValue = option;
          acc.selectOptions.push(option);
          return acc;
        },
        { selectValue: null, selectOptions: [] }
      ),
    [items, value]
  );

  return (
    <FormReactSelect
      {...props}
      labelKey="Manufacturer"
      value={selectValue}
      options={selectOptions}
      isSearchable={false}
      variant="small"
    />
  );
};

export default SelectFormManufacturers;
